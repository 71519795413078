import {Card, Checkbox, FormControlLabel, FormGroup, Grid, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateActiveFilters} from "../../reducers/items";
const Sidebar = () => {

    const {status, sectors, activeFilters} = useSelector((state) => state.items)
    const dispatch = useDispatch();

    const handleOnChange = (element) => {
        dispatch(updateActiveFilters(element))
    }
    return <Grid container rowSpacing={1} direction={'row'}>
        <Grid item xs={12} >
            <Card variant={"outlined"}>
                <Grid container p={2} direction={'column'}>
                    <Grid item>
                        <Typography variant={'h6'}>Filtrer par <b>secteurs d'activité</b> :</Typography>
                    </Grid>
                    <Grid item>
                        {sectors.map((sector, index) =>
                            <FormGroup key={index}>
                                <FormControlLabel control={<Checkbox />} label={sector} checked={activeFilters.indexOf(sector) !== -1} onChange={()=> handleOnChange(sector)}/>
                            </FormGroup>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Grid item  xs={12}>
            <Card variant={"outlined"}>
                <Grid container p={2} direction={'column'}>
                    <Grid item>
                        <Typography variant={'h6'}>Filtrer par <b>statuts</b> :</Typography>
                    </Grid>
                    <Grid item>
                        {status.map((status, index) =>
                            <FormGroup key={index}>
                                <FormControlLabel control={<Checkbox checked={activeFilters.indexOf(status) !== -1} />} label={status}  onChange={()=> handleOnChange(status)} />
                            </FormGroup>
                        )}
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    </Grid>
}

export default Sidebar