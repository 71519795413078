import MainLayout from "../MainLayout/MainLayout";
import Home from "../Home/Home";
import Items from "../Items/Items";
import Item from "../Item/Item";

const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: '/',
            element: <Home/>
        },
        {
            path: 'items',
            element: <Items/>
        },
        {
            path: 'items/:itemId',
            element: <Item/>
        },
        {
            path: '*',
            element: () => <main style={{padding: "1rem"}}>
                <p>Il n'y a rien ici!</p>
            </main>
        },
    ]
}
export default MainRoutes