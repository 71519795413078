import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Home = () => {
    const navigate = useNavigate()
    useEffect(()=> {
        navigate('/items', {replace: true})
    }, [])
    return <div>Home</div>
}
export default Home