import {useNavigate} from "react-router-dom";
import {Card, Grid, Link, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {useEffect} from "react";

const Item = () => {

    const {selectedItem} = useSelector((state) => state.items)
    const navigate = useNavigate()

    // we use useEffect to determine, on page reload, if state is wiped.
    // If it is, then we have to navigate to /items
    useEffect(() => {
        if (!Object.keys(selectedItem).length) {
            navigate('/items', {replace: true})
        }
    }, [selectedItem, navigate])


    return <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={5}>
            <Card sx={{padding: 2}}>
                <Grid container p={1} columnSpacing={1} rowSpacing={1}>
                    <Grid item p={0} container xs={12} sm={4} style={{
                        backgroundPosition: 'center',
                        backgroundOrigin: 'content-box',
                        backgroundImage: `url(${selectedItem.logo})`,
                        aspectRatio: '16/9',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Typography variant={'h2'} sx={{mb:1}}>{selectedItem.name}</Typography>
                        <Typography><b>Adresse :</b> {selectedItem.address}</Typography>
                        <Typography><b>Téléphone :</b> <Link
                            href={"tel:" + selectedItem.phone}>{selectedItem.phone}</Link></Typography>
                        <Typography><b>Email :</b> <Link
                            href={"mailto:" + selectedItem.email}>{selectedItem.email}</Link></Typography>
                        <Typography><b>Site internet :</b> <Link target={"_blank"} rel="noopener" href={selectedItem.website}>{selectedItem.website}</Link></Typography>
                        <Typography><b>Secteurs d'activité :</b> {selectedItem.sectors}</Typography>
                        <Typography><b>Status :</b> {selectedItem.status}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <p><b>Description :</b></p>
                        <p>{selectedItem.description}</p>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={7}>
            <Card sx={{padding: 2}}>
                <iframe
                    title={selectedItem.id}
                    style={{border: 'none', aspectRatio: '4/3'}}
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10664.94919728904!2d-0.7858061521887447!3d48.06706233654003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6096294c2bee3d1f!2sOrpal!5e0!3m2!1sfr!2sfr!4v1645000904305!5m2!1sfr!2sfr"
                    width="100%"
                    allowFullScreen={true}
                    loading="lazy"/>
            </Card>
        </Grid>
    </Grid>
}

export default Item