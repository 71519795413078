import { configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'

import itemsReducer from './reducers/items';
import {FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE} from "redux-persist";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}

const persistedReducer = persistReducer(persistConfig, itemsReducer)



const store = configureStore( {
    reducer: {
        items: itemsReducer,
        persistedReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
} );


export default store;