import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    items: [],
    status: [],
    sectors: [],
    activeFilters: [],
    nameFilter: '',
    selectedItem: {}
}

const NAMED_RANGES = {
    bdd: 'BDD',
    status: 'Status',
    sectors: 'Secteurs_d_activité'
}

export const initItemsState = createAsyncThunk(
    'items/initItemsState',
    async (_, thunkApi) => {

        thunkApi.dispatch(itemsLoading(true))
        const accessToken = await get_access_token_using_saved_refresh_token();
        const request = await fetch(`https://sheets.googleapis.com/v4/spreadsheets/${process.env.REACT_APP_SPREADSHEET_ID}/values:batchGet?ranges=${NAMED_RANGES.bdd}&ranges=${NAMED_RANGES.status}&ranges=${NAMED_RANGES.sectors}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                }
            });

        const {valueRanges} = await request.json();

        const bdd = valueRanges[0].values

        const status = valueRanges[1].values

        const sectors = valueRanges[2].values


        // BDD
        const bddColumns = bdd[0]

        console.log(bddColumns)
        // Translate & format French column names to English
        bddColumns.forEach((columnName, index) => {
            switch (columnName) {
                case 'Nom' :
                    return bddColumns[index] = "name"
                case 'Email' :
                    return bddColumns[index] = "email"
                case 'Description' :
                    return bddColumns[index] = "description"
                case 'Site web' :
                    return bddColumns[index] = "website"
                case 'Adresse' :
                    return bddColumns[index] = "address"
                case 'Téléphone' :
                    return bddColumns[index] = "phone"
                case 'Logo' :
                    return bddColumns[index] = "logo"
                case 'Status' :
                    return bddColumns[index] = "status"
                case "Secteurs d’activité" :
                    return bddColumns[index] = "sectors"
                default:
                    return bddColumns[index] = "error";
            }
        })

        const bddRows = []

        // i = 1 because 0 index of bdd array is taken by column names
        // here we format rows (array of string) sent by Google Api to javascript Object
        // we also add a custom id field to each row ( item ), then we can easily render a list of items with id as key parameter
        for (let i = 1; i < bdd.length; i++) {
            const obj = {}
            for (let j = 0; j < bdd[i].length; j++) {
                obj[bddColumns[j]] = bdd[i][j]
            }
            obj['id'] = i
            bddRows.push(obj)
        }


        //Status
        const statusData = status.map((element) => element[0])

        //sectors
        const sectorsData = sectors.map((element) => element[0])

        return {bddRows, statusData, sectorsData}
    }
)

const itemsReducer = createSlice({
    name: 'items',
    initialState,
    reducers: {
        itemsLoading(state, action) {
            state.loading = action.payload
        },
        updateActiveFilters(state, action) {
            state.activeFilters.indexOf(action.payload) !== -1
                ? state.activeFilters = state.activeFilters.filter((element) => element !== action.payload)
                : state.activeFilters.push(action.payload)
        },
        updateNameFilter(state, action) {
            state.nameFilter = action.payload ?? ''
        },
        updateSelectedItem(state, action) {
            state.selectedItem = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initItemsState.fulfilled, (state, action) => {
            state.items = action.payload['bddRows']
            state.status = action.payload['statusData']
            state.sectors = action.payload['sectorsData']
            state.loading = false;
        })
    }
})


const {actions, reducer} = itemsReducer;

export const {
    itemsLoading,
    updateActiveFilters,
    updateNameFilter,
    updateSelectedItem
} = actions
export default reducer;

async function get_access_token_using_saved_refresh_token() {
    // from https://developers.google.com/identity/protocols/OAuth2WebServer#offline
    const refresh_url = "https://www.googleapis.com/oauth2/v4/token";

    const post_body = `grant_type=refresh_token&client_id=${encodeURIComponent(process.env.REACT_APP_CLIENT_ID)}&client_secret=${encodeURIComponent(process.env.REACT_APP_CLIENT_SECRET)}&refresh_token=${encodeURIComponent(process.env.REACT_APP_REFRESH_TOKEN)}`;

    let refresh_request = {
        body: post_body,
        method: "POST",
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    }

    // post to the refresh endpoint, parse the json response and use the access token to call files.list
    const responseRefreshToken = await fetch(refresh_url, refresh_request)
    const responseRefreshTokenJSON = await responseRefreshToken.json()

    return responseRefreshTokenJSON.access_token;

}
