import {Card, Grid, Typography} from "@mui/material";
import './ItemsTile.css'
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {updateSelectedItem} from "../../reducers/items";

const ItemsTile = ({item}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    // when a card ( item ) is clicked, then we navigate to the detail page.
    // the detail route is : '/items/:id'
    // we also store the item in state, so it's easy for the detail page ( Item Component ) to retrieve it
    const handleOnCardClick = () => {
        dispatch(updateSelectedItem(item))
        navigate(item.name)
    }

    return <Card className={'card'} onClick={handleOnCardClick}>
        <Grid container spacing={1} p={3}>
            <Grid item p={0} container xs={12} md={3} style={{backgroundPosition: 'center', backgroundOrigin:'content-box',backgroundImage: `url(${item.logo})`, aspectRatio:'16/9', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
            </Grid>

            <Grid item container spacing={1} xs={12} md={9}>
                <Grid item xs={12}>
                    <Typography variant={"h3"}><b>{item.name}</b></Typography>
                </Grid>
                <Grid item>
                    <b>Status : </b> {item.status}<br/>
                    <b>Secteurs d'activité : </b> {item.sectors}
                </Grid>
            </Grid>
        </Grid>
    </Card>
}

export default ItemsTile