import theme from "./themes/theme";
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import React from "react";

import Routes from "./Components/Routes/Routes";
import store from "./store";
import {Provider} from "react-redux";
import {initItemsState} from "./reducers/items";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";

store.dispatch(initItemsState())
let persistor = persistStore(store)

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme()}>
                <CssBaseline/>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>

                    <Routes/>
                    </PersistGate>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
