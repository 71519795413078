import {AppBar, Autocomplete, createFilterOptions, InputAdornment, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {updateNameFilter, updateSelectedItem} from "../../reducers/items";
import SearchIcon from "@mui/icons-material/Search";
import {useNavigate} from "react-router-dom";

const filterOptions = createFilterOptions({
    limit: 5,
});

const Searchbar = () => {
    const {items} = useSelector((state) => state.items)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // here we use navigate because if users search for an item on item detail page,
    // he will be redirected to items page for the search result
    const handleOnChange = (event, newValue) => {
            console.log(newValue)
            //User cleared the input field, so we have to display all items
            if(!newValue?.name) {
                dispatch(updateNameFilter(""))
                navigate('/items')

            }else {
                dispatch(updateSelectedItem(newValue))
                navigate('/items/'+newValue.name)
            }
    }
    return <Autocomplete
        id="searchbar"
        filterOptions={filterOptions}
        noOptionsText={"Aucun résultat trouvé"}
        onChange={handleOnChange}
        getOptionLabel={(option) => option.name}
        options={items}
        renderOption={(props, option) => {
            return (
                <li {...props} key={option.id}>{option.name}</li>
            )
        }}
        renderInput={(params) => (
            <TextField
                key={params.id}
                placeholder={"Rechercher"}
                {...params}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <InputAdornment position={"start"}>
                            <SearchIcon/>
                        </InputAdornment>
                    )
                }}

            />
        )}
    />
}
export default Searchbar