import {createTheme} from '@mui/material/styles';

// assets
import {colors} from './colors'
// project imports
import componentStyleOverrides from './compStylesOverride';
import themePalette from './palette';
import themeTypography from './typography';


// A custom theme for this app
const theme = () => {

    const themeOption = {
        colors: colors,
        heading: colors.primaryMain,
        paper: colors.paper,
        backgroundDefault: colors.paper,
        background: colors.primaryLight,
        darkTextPrimary: colors.grey700,
        darkTextSecondary: colors.grey500,
        textDark: colors.grey900,
        menuSelected: colors.secondaryDark,
        menuSelectedBack: colors.secondaryLight,
        divider: colors.grey200
    };

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption)
    };

    const themes = createTheme(themeOptions);
    themes.components = componentStyleOverrides(themeOption);

    return themes;
};

export default theme;