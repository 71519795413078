import ItemsTile from "../ItemsTile/ItemsTile";
import {Grid, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";


const Items = () => {

    const {items, activeFilters, nameFilter} = useSelector((state) => state.items)

    const [itemsShown, setItemsShown] = useState([])


    // here we use useEffect to handle items showed to user.
    // as filters and searchbar input(nameFilter) can modify the items list, we have to listen to each element
    // if one or both changes, then the items list has to be rerender
    useEffect(() => {
        // if any of both filters have a value in, then we update the items list
        // else, we just send the whole items list
        if (activeFilters.length || nameFilter.length) {
            let itemsFiltered = [];

            // First, we check name filter because this filter is dominant.
            // If not, then all items will be filtered
            if (nameFilter.length) {
                itemsFiltered = items.filter((element) => element.name.includes(nameFilter))
            } else {
                itemsFiltered = items
            }

            if (activeFilters.length) {
                itemsFiltered = itemsFiltered.filter((element) => activeFilters.indexOf(element.status) !== -1 || activeFilters.indexOf(element.sectors) !== -1)
            }
            setItemsShown(itemsFiltered)

        } else {
            setItemsShown(items)
        }

    }, [activeFilters, nameFilter, items])

    return <Grid container rowSpacing={2} columnSpacing={2}>
        {itemsShown.length
            ? itemsShown.map((item) => <Grid item key={item.id}  xs={12} sm={6} lg={4}><ItemsTile item={item}/></Grid>)
            : <Grid mt={2} container justifyContent={"center"}><Typography color={"secondary"} variant={"h4"}>Aucun résultat trouvé</Typography></Grid>
        }


    </Grid>
}

export default Items