export const colors = {
// paper & background
    paper: '#e1edef',

// primary
    primaryLight: '#e3f2fd',
    primary200: '#2196f3',
    primaryMain: '#18a096',
    primaryDark: '#90caf9',
    primary800: '#1565c0',

// secondary
    secondaryLight: '#ede7f6',
    secondary200: '#673ab7',
    secondaryMain: '#e95d0f',
    secondaryDark: '#b39ddb',
    secondary800: '#4527a0',

// success
    successLight: '#b9f6ca',
    success200: '#69f0ae',
    successMain: '#00e676',
    successDark: '#00c853',

// error
    errorLight: '#ef9a9a',
    errorMain: '#f44336',
    errorDark: '#c62828',

// orange
    orangeLight: '#fbe9e7',
    orangeMain: '#ffab91',
    orangeDark: '#d84315',

// warning
    warningLight: '#fff8e1',
    warningMain: '#ffe57f',
    warningDark: '#ffc107',

// grey
    grey50: '#fafafa',
    grey100: '#f5f5f5',
    grey200: '#eeeeee',
    grey300: '#e0e0e0',
    grey500: '#9e9e9e',
    grey600: '#757575',
    grey700: '#616161',
    grey900: '#212121',
}