import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Breadcrumbs, Button, Chip, Grid, Link, Typography} from "@mui/material";
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {updateActiveFilters, updateSelectedItem} from "../../reducers/items";
import {useEffect, useState} from "react";

const routesWithoutReturnButton = ['/items']

const NavigationBar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {selectedItem, activeFilters} = useSelector((state) => state.items)

    const [showReturnButton, setShowReturnButton] = useState(false)

    const handleOnReturnButtonClick = () => {
        dispatch(updateSelectedItem({}))
        navigate(-1)
    }

    const handleOnChipDeleteClick = (element) => {
        dispatch(updateActiveFilters(element))
    }
    // useEffect will identify the current location and compare it to routesWithoutReturnButton const,
    // if the current location have to show a return button then it will refresh the state
    useEffect(()=> {
        routesWithoutReturnButton.indexOf(location.pathname) === -1 ? setShowReturnButton(true) : setShowReturnButton(false)
    }, [location])


    return <Grid container justifyContent={'space-between'} alignItems={'center'} p={1}>
        <Grid item>
            {showReturnButton
                ?  <Button variant={"text"} color={'secondary'} startIcon={<ArrowBackIcon/>} onClick={handleOnReturnButtonClick}>Retour</Button>
                : null
            }
            {/*Here we check if there is filters, so we can display them to user for a better user experience
            However, we only want to display filters when return button isn't displayed */}
            {activeFilters.length && !showReturnButton
                ?  <Typography lineHeight={2} p={1} color={"primary"}><b>Filtres :</b> {activeFilters.map((element) =><Chip key={element} color={"secondary"} size={"small"} label={element} sx={{mr: 1}} onDelete={()=>handleOnChipDeleteClick(element)} />)}</Typography>
                : null
            }
        </Grid>
        <Grid item p={1}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" component={RouterLink} to={"/items"}>
                    Associations
                </Link>
                {/*If the current location is the detail item page,
                then we want to display the current item name for a better user experience
                to do this, we check if the selectedItem from the state isn't empty  */}
                {Object.keys(selectedItem).length !== 0
                    ? <Typography color={"secondary"}>{selectedItem.name}</Typography>
                    : null
                }
            </Breadcrumbs>
        </Grid>
    </Grid>
}

export default NavigationBar