import {useLocation, Outlet} from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import NavigationBar from "../NavigationBar/NavigationBar";
import Sidebar from "../Sidebar/Sidebar";
import {Button, CircularProgress, Fab, Grid, IconButton, Modal, useMediaQuery, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';

const routesWithSidebar = ['/items'];

const MainLayout = () => {
    const location = useLocation();
    const [showSidebar, setShowSidebar] = useState(false)
    const [showSidebarMobile, setShowSidebarMobile] = useState(false)
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const {loading} = useSelector((state) => state.items)


    const handleShowSideBar = () => {
        setShowSidebarMobile(!showSidebarMobile)
    }

    // useEffect is used,here, to listen for an event triggered by useLocation.
    // If the location change then the component will check if he has to display the sidebar
    useEffect(() => {
        routesWithSidebar.indexOf(location.pathname) !== -1 ? setShowSidebar(true) : setShowSidebar(false)
    }, [location])


    return loading
        ? <Grid container height={"100%"} justifyContent={'center'} alignItems={"center"}><CircularProgress
            color="secondary"/></Grid>

        : <Grid container direction={'column'} p={2}>
            {
                // if display width is less than 600px (sm by Material ui )
                // then we have to display the FAB and modal to handle sidebar display
                showSidebar && matches
                    ? <>
                        <Fab variant="extended" color={"secondary"} sx={{
                            position: 'fixed',
                            bottom: 20,
                            right: 20,
                        }} onClick={handleShowSideBar}>
                            <FilterListIcon sx={{mr: 1}}/>
                            Filtrer
                        </Fab>
                        <Modal
                            keepMounted
                            open={showSidebarMobile}
                            onClose={handleShowSideBar}
                            sx={{overflow: 'scroll'}}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Grid container p={1} spacing={2}>
                                <Grid item container justifyContent={"end"}>
                                    <Button variant="contained" endIcon={<CloseIcon/>} onClick={handleShowSideBar}>
                                        Fermer
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Sidebar/>
                                </Grid>
                            </Grid>
                        </Modal>
                    </>
                    : null
            }
            <Grid item>
                <Searchbar/>
            </Grid>
            <Grid item>
                <NavigationBar/>
            </Grid>

            <Grid item container spacing={2}>
                {/*If MainLayout have to show the sidebar and the display width is larger than 600px*/}
                {/*then Outlet will take only 9 of 12 columns and sidebar 3 of 12, otherwise it will be full width*/}
                {
                    showSidebar && !matches
                        ? <>
                            <Grid item sm={8} md={9} xl={10}>
                                <Outlet/>
                            </Grid>
                            <Grid item sm={4} md={3} xl={2}>
                                <Sidebar/>
                            </Grid>
                        </>
                        : <Grid item xs={12}>
                            <Outlet/>
                        </Grid>
                }
            </Grid>

        </Grid>
}
export default MainLayout
