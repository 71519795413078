
export default function componentStyleOverrides(theme) {
    const bgColor = "#fff"
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: bgColor,
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                },
            }
        },
    }
}